<template>
    <div class="page-wrapper">
        <PageSection :loading="PAYMENT_LOADING">
            <div v-if="PAYMENT_DETAILS" class="col-md-8 offset-md-2">
                <p class="d-flex justify-content-between flex-column flex-sm-row">
                    {{ t('postpay_success.payment_id') }}
                    <b>{{ PAYMENT_DETAILS.id }}</b>
                </p>
                <p class="d-flex justify-content-between flex-column flex-sm-row">
                    {{ t('postpay_success.order_no') }}
                    <b>{{ PAYMENT_DETAILS.orderNo }}</b>
                </p>
                <p class="d-flex justify-content-between flex-column flex-sm-row">
                    {{ t('postpay_success.created_at') }}
                    <b>{{ new Date(PAYMENT_DETAILS.createdAt).toLocaleString() }}</b>
                </p>
                <p class="d-flex justify-content-between flex-column flex-sm-row">
                    {{ t('postpay_success.amount') }}
                    <b>{{ PAYMENT_DETAILS.amount }} {{ PAYMENT_DETAILS.currency }}</b>
                </p>
                <p class="d-flex justify-content-between flex-column flex-sm-row">
                    {{ t('postpay_success.status') }}
                    <b v-if="PAYMENT_DETAILS.status === 1">{{ t('postpay_success.status_pending') }}</b>
                    <b v-else-if="PAYMENT_DETAILS.status === 2">{{ t('postpay_success.status_charged') }}</b>
                    <b v-else>{{ t('postpay_success.status_unknown') }}</b>
                </p>

                <p v-if="PAYMENT_DETAILS.status === 1" class="text-sm-start">
                    {{ t('postpay_success.please_wait') }}
                </p>
                <p v-if="PAYMENT_DETAILS.status === 3" class="text-sm-start">
                    {{ t('postpay_success.error_occured') }}
                </p>
            </div>

            <h3 v-else>{{ t('postpay_success.not_found') }}</h3>
        </PageSection>

        <PageSection :loading="BALANCE_LOADING">
            <template v-if="BALANCE_AMOUNT > 0">
                <h2>{{ t('postpay_success.your_balance') }}</h2>
                <p class="my-3">{{ BALANCE_AMOUNT }} {{ BALANCE_CURRENCY }}</p>
                <router-link :to="{ name: 'Route_PostpayOverview' }">
                    {{ t('postpay_success.checkout_link') }}
                </router-link>
            </template>

            <h3 v-else class="m-0">{{ t('postpay_success.no_debt') }}</h3>
        </PageSection>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import PageSection from '../components/PageSection.vue';

    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    store.dispatch('balancePostpay/LOAD_BALANCE');
    store.dispatch('pagePostpaySuccess/LOAD_PAYMENT_DETAILS', route.params.paymentId?.trim() || '');

    const BALANCE_LOADING = computed(() => store.getters['balancePostpay/BALANCE_LOADING']);
    const BALANCE_AMOUNT = computed(() => store.getters['balancePostpay/BALANCE_AMOUNT']);
    const BALANCE_CURRENCY = computed(() => store.getters['balancePostpay/BALANCE_CURRENCY']);

    const PAYMENT_LOADING = computed(() => store.getters['pagePostpaySuccess/PAYMENT_LOADING']);
    const PAYMENT_DETAILS = computed(() => store.getters['pagePostpaySuccess/PAYMENT_DETAILS']);
</script>
